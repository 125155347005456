html, body, #root {
  .is-message-info {
    color: #999999;
    margin: 12px 0;
    padding: 4px 0 0 8px;
    border-left: 3px solid #cccccc;
  }

  .is-block {
    margin-top: 36px;

    &:first-child {
      margin-top: 0;
    }

    .is-block-title {
      font-size: 16px;
      font-weight: bold;

      .is-block-extra {
        float: right;
      }
    }
  }

  .is-grid {
    .ant-row {
      margin: 12px 0;
    }

    .is-grid-item-label {
      font-size: 14px;
      color: #555555;
    }

    .is-grid-item-value {
      font-size: 14px;
      color: #999999;
    }
  }

  .is-list {
    .is-list-order {
      font-size: 14px;
      color: #aa00aa;
      margin-right: 4px;

      .is-list-item {
        display: inline-block;
      }
    }

    .is-list-item {
      margin-top: 12px;
      font-size: 14px;
      color: #666666;
    }
  }
}
